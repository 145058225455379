.about-me-page {
    .sub-content {
        display: flex;

        .informations {
            .text {
                padding-right: 11rem;
            }
    
            .skills {
                display: flex;
                justify-content: space-between;
                margin-top: 6rem;
                max-width: 76rem;
            }
        }
    
        .picture {    
            > img {
                object-fit: cover;
                height: 35rem;
                width: 35rem;
                border-radius: 50%;
            }
        }
    }

    // Taille pour les téléphones
    @media (max-width: 767px) {
        .sub-content {
            flex-direction: column-reverse;
            // align-items: center;

            .informations {
                .text {
                    padding-right: 0;
                    padding-top: 1rem;
                }

                .skills {
                    .skill {
                        .title {
                            font-size: 1.8rem;
                        }

                        .icon {
                            padding: 2rem;

                            > svg {
                                height: 30px !important;
                                width: 30px !important;
                            }
                        }
                    }
                }
            }

            .picture {
                margin-top: 2rem;
                text-align: center;

                > img {
                    height: 25rem;
                    width: 25rem;
                }
            }
        }
    }
}