@import '../../../assets/styles/colors';

.project-page {
    .sub-content {
        display: flex;
        
        .picture{
            > img {
                height: 40rem;
            }
        }

        .details {
            padding-left: 4rem;
            padding-right: 3rem;

            .icons {
                display: flex;
                margin-top: 8rem;

                svg {
                    padding-right: 4.8rem;
                }
            }
        }
    }

    // Taille pour les téléphones
    @media (max-width: 767px) {
        .sub-content {
            flex-direction: column;
            align-items: center;

            .picture {
                margin-top: 2rem;
                
                > img {
                    height: 20rem;
                }
            }

            .details {
                padding: 0;
    
                .icons {
                    justify-content: center;
                }
            }
        }
    }
}