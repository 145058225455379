@import '../../../assets/styles/colors';

.menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .item{
        position: relative;
        color: $white-75;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        align-items: center;

        .group-item{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0.6rem;

            > span{
                font-size: 1.3rem;
            }
        }

        .separate-line{
            background-color: $white-75;
            margin-bottom: 0.6rem;
            -webkit-transition: height 1s; /* For Safari 3.1 to 6.0 */
            transition: height 1s;
        }

        .circle{
            height: 1.5rem;
            width: 1.5rem;
            border-radius: 50%;
            background-color: $white-75;
            margin-bottom: 0.6rem;
            -webkit-transition: background-color 1s; /* For Safari 3.1 to 6.0 */
            transition: background-color 1s;
        }        
    }
}

@media screen and (max-width: 1000px) {

    .menu {
        flex-direction: row;
        
        .item {
            flex-direction: row;
            align-items: flex-start;

            .group-item {
                width: 10rem;
                text-align: center;
            }

            .separate-line {
                margin-top: 0.6rem;
                -webkit-transition: width 1s; /* For Safari 3.1 to 6.0 */
                transition: width 1s;
            }
        }
    }
}

@media screen and (max-width: 700px) {

    .menu {
        .item {
            .separate-line {
                display: none;
            }
        }
    }
}