.contact-page{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: flex-start !important;
    padding-top: 3rem;
    padding-bottom: 1rem;

    .input-cpt {
        margin-bottom: 2.6rem;
    }
}