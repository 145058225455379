@import '../../../assets/styles/colors';

$red-pink: rgba(206, 26, 69, .9);

.home-page {
    display: flex;
    flex: 1;
    height: 100vh;
    width: 100%;
    background-color: $primary-background;
    flex-direction: column;

    // Slide screen
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.5s ease-in-out;
    transform: translateY(-100%);

    img {
        position: absolute;
        z-index: -1;
        bottom: -20px;
        left: 0;
        right: 0;
        width: auto;
        height: inherit;
        max-height: 92%;
        margin: auto;
        filter: drop-shadow(5px 0 0 white) 
                drop-shadow(0 5px 0 white)
                drop-shadow(-5px 0 0 white) 
                drop-shadow(0 -5px 0 white)
                opacity(0.2);
    }

    &.active {
        transform: translateY(0%);
    }

    .title {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 14.4rem;
        z-index: 999;

        .is {
            font-size: 2.5rem;
            margin-bottom: -8px;
        }

        .name {
            font-size: 9.6rem;
            color: $white;
            margin-bottom: -5px;
        }

        .subtitle {
            display: flex;
            flex-direction: column;
            align-items: center;

            .job {
                font-size: 2.7rem;
                color: $primary;
                text-transform: uppercase;
            }
    
            .joke {
                font-size: 1.5rem;
                color: $primary;
                text-transform: uppercase;
            }            
    
            &:hover {
                .job, .joke {
                    color: $red-pink;
                }
            }
        }
    }

    .content {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        display: flex;
        align-items: flex-end;
        margin-bottom: 4rem;
        padding-left: 8rem;
        padding-right: 8rem;
        z-index: 999;

        >div {
            flex: 1;
        }

        .social-network {
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            >span {
                font-size: 2rem;
                font-weight: bold;
                padding-left: 2rem;
            }

            .icons {
                display: flex;
                margin-top: 1.6rem;
                justify-content: space-between;
                gap: 2rem;

                >svg {
                    height: 25px !important;

                    &:first-child {
                        height: 30px !important;
                    }
                }
            }
        }

        .discover-me {
            position: relative;
            text-align: center;

            >span {
                font-size: 1.6rem;
                text-transform: uppercase;
            }

            .arrow {
                background: #fff;
                height: 2px;
                width: 2px;
                margin: 0 auto;
                position: relative;
                cursor: pointer;
                margin-bottom: 7px;
                z-index: 999;

                &:before,
                &:after {
                    content: "";
                    background: #fff;
                    position: absolute;
                    height: 2px;
                    width: 10px;
                }

                &:before {
                    left: -7.5px;
                    bottom: 3px;
                    transform: rotate(45deg);
                }

                &:after {
                    left: -1px;
                    bottom: 3px;
                    transform: rotate(135deg);
                }
            }

            .circle {
                position: absolute;
                background-color: $primary;
                height: 2.5rem;
                width: 2.5rem;
                border-radius: 50%;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 0;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .dev {
            font-family: 'Roboto', sans-serif;
            font-size: 3.8rem;
            text-align: right;

            .dot {
                color: $primary;
            }
        }
    }
}

// Taille pour les téléphones
@media (max-width: 767px) {
    .home-page {
        .content {
            align-items: flex-end;
            padding-left: 2rem;
            padding-right: 2rem;

            >div {
                flex: 1;
            }

            .social-network {
                >span {
                    padding-left: 3rem;
                }

                .icons {
                    justify-content: center;
                }
            }
        }
    }
}