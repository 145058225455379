@import '../../../assets/styles/colors';

.skill{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15rem;
    text-align: center;

    .icon{
        border: 1px solid $primary;
        border-radius: 50%;
        padding: 2.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title{
        margin-top: 0.9rem;
        font-size: 2.4rem;
        color: $primary;
    }
}