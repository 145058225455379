@import '../../../assets/styles/colors';

.step-frame{
    max-width: 20rem;
    height: 50rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    display: flex;
    color: $white-75;
    padding: 2rem 1rem;

    .informations{
        display: flex;
        flex-direction: column;
        align-items: center;

        .title{
            font-size: 3.2rem;
        }

        .sub-title{
            font-size: 1.9rem;
        }
    }

    .icons{
        display: flex;
        flex-direction: column;

        svg{
            padding: 0.8rem 0;
        }
    }

    .year{
        font-size: 1.9rem;        
    }
}