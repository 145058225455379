@import '../../../assets/styles/colors';

.button-cpt {
    padding: 2rem 2.4rem;
    background-color: $primary;
    color: $white;
    height: 7rem;
    max-width: 20rem;
    width: 100%;
    font-size: 1.8rem;
    text-align: center;
    text-decoration: none;
    align-items: center;
    display: flex;
    justify-content: center;

    &.disabled {
        opacity: 0.5;
        cursor: default;
    }
}

// Taille pour les téléphones
@media (max-width: 767px) {
    .button-cpt {
        height: 6rem;
        max-width: 16rem;
    }
}