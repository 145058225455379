@import 'assets/styles/colors';

.screen {
    position: relative;
    height: 100vh;
    overflow: hidden;

    // Global grid
    .container-grid {
        background-color: $primary-background;
        display: grid;
        height: 100vh;
        width: 100%;
        grid-template-columns: repeat(3, 1fr); // 3 colonnes égales en largeur
        grid-gap: 1rem; // espace entre les colonnes
        grid-template-rows: auto 1fr auto;
        overflow: hidden;

        // Slide screen
        position: absolute;
        top: 0;
        left: 0;
        transition: transform 0.5s ease-in-out;
        transform: translateY(100%);

        &.active {
            transform: translateY(0%);
        }

        // Big title
        .big-title {
            grid-column: 2 / -1;
            padding-top: 9rem;
        }

        // Container page
        .container {
            >div {
                width: 100% !important;
                overflow: hidden;

                .content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                }
            }
        }
    }
}


// Taille pour les téléphones
@media (max-width: 767px) {
    .screen {
        .container-grid {
            display: flex;
            flex-direction: column;
            padding: 0 4rem;
            justify-content: space-between;
            overflow: auto;

            .big-title {
                padding-top: 3rem;
            }

            .container {
                .content {
                    margin-bottom: 4rem;
                }
            }
        }
    }
}

// Taille pour les tablettes
// @media (min-width: 768px) and (max-width: 991px) {
//     .container {
//         max-width: 720px;
//         padding: 0 20px;
//     }

//     .col {
//         width: calc(33.3333% - 20px);
//         margin-right: 20px;
//         margin-bottom: 20px;

//         &:last-child {
//             margin-right: 0;
//         }
//     }
// }