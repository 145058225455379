@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&family=Varela+Round&display=swap');
@import './assets/styles/colors';

body {
    input, textarea {
        all: unset;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

// border-box => prends en compte le padding dans la hauteur et largeur
*, ::before, ::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

:root {
    font-size: 62.5%;

    body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: 'Varela Round', sans-serif;
        color: $white;
        overflow: hidden;

        h1, h4{
            margin: 0;
            padding: 0;
        }

        p, a {
            font-size: 2.4rem;
            color: $white-70;
        }

        svg {
            -webkit-box-sizing: initial;
            -moz-box-sizing: initial;
            box-sizing: initial;
        }        

        // Fonts
        .bold{
            font-weight: bold;
        }

        // Cursors
        .pointer{
            cursor: pointer;
        }

        .select-disable {
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -o-user-select: none;
            user-select: none;
        }

        // CSS Effect
        .fade-out{
            opacity:0;
            transition: z-index 1s, opacity 0.5s;
            z-index: -1;
        }
        .fade-in{
            opacity:1;
            transition: opacity 1s 0.5s;
            z-index: 0;
        }

        // COLORS
        .color-primary{
            color: $primary;
        }

        .color-white-50{
            color: $white-50;
        }

        .bg-color-primary{
            background-color: $primary !important;
        }
    }
}

// Taille pour les téléphones
@media (max-width: 767px) {
    :root {
        font-size: 50%;
    }
}