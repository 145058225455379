@import '../../../assets/styles/colors';

.big-title{
    h1, h4{
        font-weight: normal;
    }

    .subtitle{
        font-size: 5rem;
        text-transform: uppercase;

        .letter {
            display: inline-block;
            line-height: 1em;
            white-space: pre;
        }
    }

    h4{
        font-size: 2rem;
        color: $primary;
        text-transform: uppercase;
    }
}