@import '../../../assets/styles/colors';

.footer {
    background-color: $primary-background;
    height: 6rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: space-between;
    font-size: 1.8rem;
    grid-column: 2 / -1;
    padding-right: 7rem;
    padding-bottom: 4rem;

    .dev {
        font-family: 'Roboto', sans-serif;
        font-size: 3rem;
        text-align: right;
    }
}

// Taille pour les téléphones
@media (max-width: 767px) {
    .footer {
        padding-right: 0;
        padding-bottom: 2rem;
    }
}