@import '../../../assets/styles/colors';

.career-page {
    justify-content: space-between !important;
    margin-right: 14rem;
    padding: 4.5rem 0;

    .step-frame {
        height: 100%;
    }
}

// Taille pour les téléphones
@media (max-width: 767px) {
    .career-page {
        margin-bottom: 0 !important;
        align-items: center;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 3rem;

        .icon-step {
            text-align: center;
        }
    }
}