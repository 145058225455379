@import '../../../assets/styles/colors';

.input-cpt {
    border: 1px solid $white;
    padding: 2rem 2.4rem;
    background-color: transparent;
    color: $white;
    height: 7rem;
    max-width: 70rem;
    width: 100%;
    font-size: 1.8rem;

    .input {
        padding-bottom: 0.7rem;
        width: 100%;
        height: 100%;
    }

    .error {
        font-size: 1rem;
        color: rgb(243, 89, 89);
    }
}