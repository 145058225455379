
// Background
$primary-background: #101010;

// White
$white: #FFFFFF;
$white-75: rgba(255, 255, 255, 0.75);
$white-70: rgba(255, 255, 255, 0.7);
$white-50: rgba(255, 255, 255, 0.5);

// Common
$primary: #1D43A6;
$secondary: rgba(29, 67, 166, 0.55);
